import {withGlobals} from '../../../../globalPropsContext';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Experiments, GALLERY_TYPE} from '../../../../constants';
import React, {useCallback} from 'react';
import s from './SliderGalleryTitle.scss';
import {Text} from 'wix-ui-tpa/cssVars';
import classnames from 'classnames';
import {ConditionalRender} from '../../../../common/components/ConditionalRender/ConditionalRender';
import {classes} from './SliderGalleryTitle.st.css';
import {ISliderGlobalProps} from '../../../sliderGlobalStrategy';
import {useExperiments} from '@wix/yoshi-flow-editor';

export enum SliderGalleryTitleDataHook {
  Title = 'SliderGalleryTitleDataHook.Title',
}

export const SliderGalleryTitle = withGlobals(
  withTranslations()(({globals}: ISliderGlobalProps) => {
    const {textsMap, galleryType} = globals;
    const {experiments} = useExperiments();
    const hideArrowsOnSliderGalleryWithFewerProductsThanColumns = experiments.enabled(
      Experiments.HideArrowsOnSliderGalleryWithFewerProductsThanColumns
    );

    const titleShownByDefault =
      galleryType === GALLERY_TYPE.RELATED_PRODUCTS || galleryType === GALLERY_TYPE.ALGORITHMS;

    const renderTitle = useCallback(() => {
      const htmlTag = globals.htmlTags.headerTextHtmlTag;

      return (
        <Text
          tagName={htmlTag}
          className={classnames(classes.root, s.title, {
            [s.withHideArrows]: hideArrowsOnSliderGalleryWithFewerProductsThanColumns,
          })}
          data-hook={SliderGalleryTitleDataHook.Title}>
          {textsMap.sliderGalleryTitle}
        </Text>
      );
    }, [
      globals.htmlTags.headerTextHtmlTag,
      textsMap.sliderGalleryTitle,
      hideArrowsOnSliderGalleryWithFewerProductsThanColumns,
    ]);

    return (
      <>
        <ConditionalRender by={'showTitleWasTurnedOn'}>{renderTitle()}</ConditionalRender>
        {titleShownByDefault && <ConditionalRender by={'showTitleWasNeverChanged'}>{renderTitle()}</ConditionalRender>}
      </>
    );
  })
);
